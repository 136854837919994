import { isHubsRoomUrl, isLocalHubsAvatarUrl, isLocalHubsSceneUrl, isLocalHubsUrl } from "../utils/media-url-utils";

import { changeHub } from "../change-hub";
import { guessContentType } from "../utils/media-url-utils";
import { handleExitTo2DInterstitial } from "../utils/vr-interstitial";

const axios = require('axios').default;

AFRAME.registerComponent("open-media-button", {
  schema: {
    onlyOpenLink: { type: "boolean" }
  },
  init() {
    this.label = this.el.querySelector("[text]");

    this.updateSrc = async () => {
      if (!this.targetEl.parentNode) return; // If removed
      const mediaLoader = this.targetEl.components["media-loader"].data;
      const src = (this.src = (mediaLoader.mediaOptions && mediaLoader.mediaOptions.href) || mediaLoader.src);
      const visible = src && guessContentType(src) !== "video/vnd.hubs-webrtc";
      const mayChangeScene = this.el.sceneEl.systems.permissions.canOrWillIfCreator("update_hub");

      this.el.object3D.visible = !!visible;

      if (visible) {
        let label = "Go!";
        if (!this.data.onlyOpenLink) {
          let hubId;
          if (src.includes("/redeem?code=")) {
            label = "Click to Collect";
          } else if (await isLocalHubsAvatarUrl(src)) {
            label = "Use avatar";
          } else if ((await isLocalHubsSceneUrl(src)) && mayChangeScene) {
            label = "Use scene";
          } else if ((hubId = await isHubsRoomUrl(src))) {
            const url = new URL(src);
            if (url.hash && window.APP.hub.hub_id === hubId) {
              label = "Go";
            } else {
              label = "Go";
            }
          }
          console.log("this.attrName", this.attrName)
        }
        this.label.setAttribute("text", "value", label);

      }

    };

    this.onClick = async () => {
      const endpoint = "https://4foi2wvdgh.execute-api.us-east-1.amazonaws.com/prod/redemption";

      let email = JSON.parse(localStorage.getItem("email"));

      if (this.src.includes("code=")) { // If collecting a gem
        
        const code = this.src.split("code=").pop();
        
        let sendData = JSON.stringify({
          email,
          code
        });
        
        if (code.includes('hiddengem-')) {
          axios.put(endpoint, sendData)
          .then(({status}) => {
            if (status === 202) {
              // playAudio("https://hultverseawsstack-staticresourcesbucket920b6e1d-psxzwg5s4aml.s3.amazonaws.com/gem-found.mp3")
              // .then(() => {
                alert("Hidden Gem Collected!");
              // })
            } else if (status === 204) {
              alert("You've Already Collected This Gem");
            }
          })
          .catch((error) => {
            console.log({error});
            console.log("oops... didn't work");
            alert("Error collecting Gem");
          })
        }

        function playAudio(url) {
          return new Promise(resolve => {
            var aud = new Audio(url);
            aud.addEventListener('loadedmetadata', () => {
              let duration = aud.duration * 1000;
              setTimeout(resolve, duration);
            });
            aud.play();
          })
        }

        return;

      } else {
        const theURL = new URL(this.src);
        const origin = theURL.origin;
        const href = theURL.href;

        let code = null;

        code = href.replace(origin, "");
        code = code.replaceAll("/", "");

        if (code) {
          let sendData = JSON.stringify({
            email,
            code
          });
          axios.put(endpoint, sendData);
        }
      }

      const mayChangeScene = this.el.sceneEl.systems.permissions.canOrWillIfCreator("update_hub");

      const exitImmersive = async () => await handleExitTo2DInterstitial(false, () => {}, true);

      let hubId;
      if (this.data.onlyOpenLink) {
        await exitImmersive();
        window.open(this.src);
      } else if (await isLocalHubsAvatarUrl(this.src)) {
        const avatarId = new URL(this.src).pathname.split("/").pop();
        window.APP.store.update({ profile: { avatarId } });
        this.el.sceneEl.emit("avatar_updated");
      } else if ((await isLocalHubsSceneUrl(this.src)) && mayChangeScene) {
        this.el.sceneEl.emit("scene_media_selected", this.src);
      } else if ((hubId = await isHubsRoomUrl(this.src))) {
        const url = new URL(this.src);
        if (url.hash && window.APP.hub.hub_id === hubId) {
          // move to waypoint w/o writing to history
          window.history.replaceState(null, null, window.location.href.split("#")[0] + url.hash);
        } else if (APP.store.state.preferences.fastRoomSwitching && isLocalHubsUrl(this.src)) {
          // move to new room without page load or entry flow
          changeHub(hubId);
        } else {
          await exitImmersive();
          location.href = this.src;
        }
      } else {
        await exitImmersive();
        window.open(this.src);
      }
    };

    NAF.utils.getNetworkedEntity(this.el).then(networkedEl => {
      this.targetEl = networkedEl;
      this.targetEl.addEventListener("media_resolved", this.updateSrc, { once: true });
      this.updateSrc();
    });
  },

  play() {
    this.el.object3D.addEventListener("interact", this.onClick);
  },

  pause() {
    this.el.object3D.removeEventListener("interact", this.onClick);
  }
});
